import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from 'classnames';
import useDelay from '../hooks/useDelay';

const Wrapper = styled.div`
	@keyframes circle-animation {
		0%,
		25% {
			stroke-dashoffset: 280;
			transform: rotate(0);
		}

		50%,
		75% {
			stroke-dashoffset: 75;
			transform: rotate(45deg);
		}

		100% {
			stroke-dashoffset: 280;
			transform: rotate(360deg);
		}
	}

	@keyframes svg-animation {
		0% {
			transform: rotateZ(0deg);
		}

		100% {
			transform: rotateZ(360deg);
		}
	}

	svg {
		animation: 2s linear infinite svg-animation;
		height: 100%;
		width: 100%;
	}

	circle {
		animation: 1.4s ease-in-out infinite both circle-animation;
		display: block;
		fill: transparent;
		stroke: rgba(0, 0, 0, 0.3);
		stroke-linecap: round;
		stroke-dasharray: 283;
		stroke-dashoffset: 280;
		stroke-width: 10px;
		transform-origin: 50% 50%;
	}

	& {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		padding: 3px;
	}

	&.dark circle {
		stroke: rgba(255, 255, 255, 0.4);
	}
`;

const Spinner = ({children, style, circleStyle, size, dark, delay}) => {
	const ready = useDelay(delay);
	const spinnerStyle = Object.assign({height: size, width: size}, style);

	const className = classNames({
		spinner: true,
		dark
	});

	if (!ready) {
		return null;
	}

	return (
		<>
			<Wrapper className={className} style={spinnerStyle}>
				<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
					<circle cx="50" cy="50" r="45" style={circleStyle} />
				</svg>
			</Wrapper>
			<span className="spinner-text">{children}</span>
		</>
	);
};

Spinner.propTypes = {
	children: PropTypes.any,
	size: PropTypes.string,
	dark: PropTypes.bool,
	delay: PropTypes.number,
	style: PropTypes.object,
	circleStyle: PropTypes.object
};

Spinner.defaultProps = {
	size: '24px',
	dark: false,
	delay: 1000,
	style: {},
	circleStyle: {}
};

export default Spinner;
